////////////////////////////////////////////////////////////////
//
//  ©PRAKT
//
////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////
//
//  TITLE SCROLLER
//
////////////////////////////////////////////////////////////////

export default class TitleScoller {

    constructor( target ) {
        this.title_container = target;
        this.title;
        this.word_list;
        this.loop_listener;
        this.x_positions;
        this.speed;
        this.timeout;
        this.title_width;
    }

    init() {
        this.title = this.title_container;
        const text = this.title.innerHTML;
        this.title.setAttribute( 'data-title', text );
        this.x_positions = [];
        this.speed = 1;
        setTimeout( () => {
            this.set();
            this.loop();
            this.resize();
        }, 200 );
    }

    set() {
        this.title_width = this.title.getBoundingClientRect().width;
        this.title.style.display = 'flex';
        this.title.innerHTML = '';
        
        let max_word_block = Math.ceil( window.innerWidth / this.title_width );
        if( max_word_block >= 3 ) max_word_block = 5;
        else max_word_block = 5;

        for (let i = 0; i < max_word_block; i++) {
            let word_block = document.createElement( 'span' );
            word_block.className = 'word_block';
            word_block.innerHTML = this.title.dataset.title;
            this.title.appendChild( word_block );
        }
        this.word_list = this.title.querySelectorAll( '.word_block' );
        this.word_list.forEach( o => {
            this.x_positions.push( o.getBoundingClientRect().x );
        });
    }

    move() {
        let element_width = this.word_list[ 0 ].getBoundingClientRect().width;
        let x_position_restart = element_width * ( this.word_list.length - 1 );

        for ( let i = 0; i < this.word_list.length; i++ ) {
            if( ( this.x_positions[ i ] ) < ( element_width * -1 ) ) {
                this.x_positions[ i ] = x_position_restart;
            }
            this.x_positions[ i ] -= this.speed;
            let new_pos_x = this.x_positions[ i ] - ( i * element_width );
            this.word_list[ i ].style = 'transform: translate( '+ new_pos_x +'px, 0 )';
        }
    }

    loop() {
        this.move();
        this.loop_listener = requestAnimationFrame( this.loop.bind( this ) );
    }

    kill() {
        cancelAnimationFrame( this.loop_listener );
    }

    clear() {
        this.title.innerHTML = this.title.dataset.title;
        this.title.style.display = 'inline-flex';
        this.x_positions = [];
    }

    resize() {
        window.addEventListener( 'resize', () => {
            clearTimeout( this.timeout );
            this.timeout = setTimeout( () => {
                this.kill();
                this.clear();
                this.set();
                this.loop();
            }, 200 );
        } );
    }
}