////////////////////////////////////////////////////////////////
//
//  ©PRAKT
//
////////////////////////////////////////////////////////////////

import gsap from 'gsap';
import { scroll } from '../index';

////////////////////////////////////////////////////////////////
//
//  ORDER PANEL
//
////////////////////////////////////////////////////////////////

export default class OrderPanel {

    constructor() {
        this.panel;
        this.panel_content;
        this.panel_overlay;
        this.panel_close;
        this.init();
    }

    init() {
        this.panel = document.querySelector( '#order_panel' );
        this.panel_overlay = this.panel.querySelector( '.overlay' );
        this.panel_content = this.panel.querySelector( '.content_container' );
        this.panel_close = this.panel.querySelector( '.close' );
        this.buyButtons();
        this.orderButtons();
        this.closeButtonAndOverlayAction();
        this.buyForm();
    }

    buyButtons() {
        const buy_buttons = document.querySelectorAll( '.button_container .buy' );
        buy_buttons.forEach( o => {
            o.addEventListener( 'pointerup', () => {
                let info_message = "Pour connaître la disponibilité de cette toile originale, merci de laissez vos coordonnées ci-dessous.";
                let parent = o.parentNode.parentNode.parentNode;
                let title = parent.querySelector( '.title' ).innerHTML;
                let dimensions = parent.querySelector( '.dimensions' ).innerHTML;
                let price = o.parentNode.parentNode.querySelector( '.price' );
                if( price ) price = o.parentNode.parentNode.querySelector( '.price' ).innerHTML;
                else price = '';
                let img = parent.parentNode.querySelector( '.img_container' ).innerHTML;

                this.panel.querySelector( '.buy_container .info' ).innerHTML = info_message;
                this.panel.querySelector( '.buy_container .details .title' ).innerHTML = title;
                this.panel.querySelector( '.buy_container .details .dimensions' ).innerHTML = dimensions;
                this.panel.querySelector( '.buy_container .details .price' ).innerHTML = price;
                this.panel.querySelector( '.buy_container .details .img' ).innerHTML = img;
                this.panel.querySelector( '.buy_container' ).style.display = 'block';
                this.openPanel();
            } );
        });
    }

    orderButtons() {
        const order_buttons = document.querySelectorAll( '.button_container .order' );
        order_buttons.forEach( o => {
            o.addEventListener( 'pointerup', () => {
                let info_message = "Pour commander cette reprographie, merci de laissez vos coordonnées ci-dessous.";
                let parent_title = o.parentNode.parentNode.parentNode.parentNode.parentNode;
                let title = parent_title.querySelector( '.title' ).innerHTML;
                let dimensions = o.parentNode.parentNode.querySelector( '.details .dimensions' ).innerHTML;
                let price = o.parentNode.parentNode.querySelector( '.details .price' ).innerHTML;
                let img = o.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.querySelector( '.img_container' ).innerHTML;
                let ref = 'repro-'+title+'-'+dimensions;
                ref = ref.replace( ' ', '' );
                ref = ref.replace( 'cm', '' );

                this.panel.querySelector( '.buy_container .info' ).innerHTML = info_message;
                this.panel.querySelector( '.buy_container .details .title' ).innerHTML = title + ' (reprographie)';
                this.panel.querySelector( '.buy_container .details .dimensions' ).innerHTML = dimensions;
                this.panel.querySelector( '.buy_container .details .price' ).innerHTML = price;
                this.panel.querySelector( '.buy_container .details .img' ).innerHTML = img;
                this.panel.querySelector( '.buy_container' ).style.display = 'block';
                this.openPanel();
            } );
        });
    }

    openPanel() {
        scroll.stopScroll();
        this.panel.style.display = 'block';
        gsap.fromTo( this.panel_overlay, {
            opacity: 0
        }, {
            duration: 0.5,
            ease: 'power3.inOut',
            opacity: 1
        } );

        gsap.fromTo( this.panel_content, {
            xPercent: 100
        }, {
            duration: 0.5,
            ease: 'power3.inOut',
            xPercent: 0
        } );
    }

    closePanel() {
        gsap.to( this.panel_overlay, {
            duration: 0.5,
            ease: 'power3.inOut',
            opacity: 0,
            onComplete: () => {
                this.panel.style.display = 'none';
                this.panel.querySelector( '.buy_container' ).style.display = 'none';
                // this.panel.querySelector( '.order_container' ).style.display = 'none';
                scroll.startScroll();
            }
        } );

        gsap.to( this.panel_content, {
            duration: 0.5,
            ease: 'power3.inOut',
            xPercent: 100
        } );

        const message = document.querySelector( '#buy_form .form__message' );
        message.style.display = 'none';
    }

    closeButtonAndOverlayAction() {
        this.panel_overlay.addEventListener( 'pointerup', () => {
            this.closePanel();
        } );

        this.panel_close.addEventListener( 'pointerup', () => {
            this.closePanel();
        } );
    }

    buyForm() {
        const form = document.querySelector( '#buy_form' );
        const button = form.querySelector( '.form__valid' );
        const message = form.querySelector( '.form__message' );
        let form_public_response;

        async function sendRequest( formatted_form_data ){
            const response = await fetch( 'inc/sendBuyRequest.php', {
                method: 'POST',
                body: formatted_form_data
            });
            // const data = await response.text(); // echo php
            if( response.status == 200 ) {
                form_public_response = 'Merci, votre demande est envoyée!';
                message.style.display = 'block';
                message.innerHTML = form_public_response;
                // sendUserConfirm( formatted_form_data );
                clearForm();
            } else if ( response.status == 500 || response.status == 403 ) {
                form_public_response = 'Il y a eu un problème, merci de réessayer';
                message.style.display = 'block';
                message.innerHTML = form_public_response;
            }
        }

        async function sendUserConfirm( formatted_form_data ){
            const response = await fetch( 'inc/buyUserConfirm.php', {
                method: 'POST',
                body: formatted_form_data
            });
            // const data = await response.text(); // echo php
            // console.log( response.status );
            if( response.status == 200 ) {
                // console.log('Confirm sent');
            } else if ( response.status == 500 || response.status == 403 ) {
                // console.log('Confirm error');
            }
        }

        function getPieceData() {
            let buy_title = document.querySelector( '.buy_container .details .title' );
            let order_title = document.querySelector( '.order_container .details .title' );
            let container;
            if( buy_title != '' ) container = document.querySelector( '.buy_container' );
            else if( order_title != '' ) container = document.querySelector( '.order_container' );
            const piece = container.querySelector( '.details .title' ).innerHTML;
            const dimensions = container.querySelector( '.details .dimensions' ).innerHTML;
            const price = container.querySelector( '.details .price' ).innerHTML;
            form.querySelector( '#piece' ).value = piece;
            form.querySelector( '#dimensions' ).value = dimensions;
            form.querySelector( '#price' ).value = price;
        }

        function validateEmail(email) {
            var re = /\S+@\S+\.\S+/;
            return re.test(email);
        }

        function validFrom() {
            const lastname = form.querySelector( '#lastname' ).value;
            const email = form.querySelector( '#email' ).value;
            let error = '';
            
            if( lastname == '' ) {
                error += "Entrez votre nom.\n";
            }

            if ( email == '' ) {
                error += "Entrez votre email.\n";
            } else if ( !validateEmail(email) ) {
                error += "Entrez un email valid.\n";
            }

            if (error != '' ) {
                // alert(error);
                message.innerHTML = error;
                message.style.display = 'block';
                return false;
            }
        }

        function clearForm() {
            const input_text = form.querySelectorAll( 'input[ type=text ]' );
            const input_email = form.querySelector( 'input[ type=email ]' );
            const input_tel = form.querySelector( 'input[ type=tel ]' );
            const textarea = form.querySelector( 'textarea' );
            input_text.forEach( o => {
                o.value = '';
            });
            input_email.value = '';
            input_tel.value = '';
            textarea.value = '';
        }

        button.addEventListener( 'click', ( event ) => {
            event.preventDefault();
            validFrom();
            getPieceData();
            const formatted_form_data = new FormData(form);
            sendRequest( formatted_form_data );
        } );
    }
}