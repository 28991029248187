////////////////////////////////////////////////////////////////
//
//  ©PRAKT
//
////////////////////////////////////////////////////////////////

import gsap from 'gsap';

////////////////////////////////////////////////////////////////
//
//  COOKIE
//
////////////////////////////////////////////////////////////////

export default class Cookie {

    constructor() {
        this.cookie_name;
        this.cookie_box;
        this.init();
    }

    init() {
        this.cookie_name = 'VA_WEBSITE_ACCEPTANCE';
        this.cookie_box = document.querySelector( '#cookie_notice' );
        this.checkCookie();
    }

    setCookie() {
        const cname = this.cookie_name;
        const cvalue = 'VA_WEBSITE_ACCEPTANCE_GRANTED';
        const exdays = 30;
        const d = new Date();
        d.setTime( d.getTime() + ( exdays * 24 * 60 * 60 * 1000 ) );
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    getCookie() {
        let name = this.cookie_name + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }

    checkCookie() {
        let username = this.getCookie( this.cookie_name );
        if ( username != '' ) {
            // Already set
            this.cookie_box.remove();
        } else {
            // Not set
            this.showCookieBox();
            this.cookieAcceptanceButton();
        }
    }

    cookieAcceptanceButton() {
        const button = document.querySelector( '#cookie_notice .button' );
        button.addEventListener( 'click', () => {
            this.setCookie();
            this.hideCookieBox();
        } );
    }

    showCookieBox() {
        this.cookie_box.style.display = 'flex';
    }

    hideCookieBox() {
        gsap.to( this.cookie_box, {
            duration: 0.5,
            ease: 'power2.inOut',
            opacity: 0,
            onComplete: () => {
                this.cookie_box.remove();
            }
        } );
    }
}