////////////////////////////////////////////////////////////////
//
//  ©PRAKT
//
////////////////////////////////////////////////////////////////

import GLOBALS from './globals';
import * as responsive from './utils/responsive';
// import Navigation from './classes/Navigation';
import Scroll from './classes/Scroll';
import Animation from './classes/Animation';
// import Transition from './classes/Transition';
// import RAF from './classes/RAF';
// import Videoblock from './classes/Videoblock';
// import Loader from './classes/Loader';
import Home from './classes/Home';
import HeaderSlide from './classes/HeaderSlide';
import OrderPanel from './classes/OrderPanel';
import ReprographyDrawer from './classes/ReprographyDrawer';
import Cookie from './classes/Cookie';

////////////////////////////////////////////////////////////////
//
//  PROJECT 
//
////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////
//
//  BASIC RESPONSIVE
//
////////////////////////////////////////////////////////////////

responsive.cssSetTouchDevices( 'html', 'touch', 'no_touch' );

////////////////////////////////////////////////////////////////
//
//  INTERFACE
//
////////////////////////////////////////////////////////////////

// export const navigation = new Navigation();
export const scroll = new Scroll();
// export const transition = new Transition();
export const animation = new Animation();
// export const videoblock = new Videoblock();
const home = new Home();
const headerslide = new HeaderSlide();
const orderpanel = new OrderPanel();
const reprographydrawer = new ReprographyDrawer();
const cookie = new Cookie();

////////////////////////////////////////////////////////////////
//
//  CONTENT
//
////////////////////////////////////////////////////////////////

// export const var = new Class();

////////////////////////////////////////////////////////////////
//
//  RAF
//
////////////////////////////////////////////////////////////////

// const raf = new RAF();

////////////////////////////////////////////////////////////////
//
//  LOADER
//
////////////////////////////////////////////////////////////////

// const loader = new Loader();

////////////////////////////////////////////////////////////////
//
//  READY
//
////////////////////////////////////////////////////////////////

// document.addEventListener("DOMContentLoaded", (e) => { 
//     // scroll.init();
//     // scroll.action();
//     // transition.init();
// });

////////////////////////////////////////////////////////////////
//
//  ON LOAD
//
////////////////////////////////////////////////////////////////

window.addEventListener('load', () => {
    // loader.hideLoader();
    scroll.init();
    scroll.action();
    home.checkHash();
    // raf.render();
})