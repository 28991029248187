////////////////////////////////////////////////////////////////
//
//  ©PRAKT
//
////////////////////////////////////////////////////////////////

import * as responsive from '../utils/responsive';
import gsap from 'gsap';

////////////////////////////////////////////////////////////////
//
//  SLIDE
//
////////////////////////////////////////////////////////////////

export default class Slide {

    constructor( target_ ) {
        this.target = target_;
        this.counter;
        this.item_list;
        this.slide_scroll;
        this.last_active_item;
        if( responsive.minBreakpoint( RSP_LG ) ) this.init();
    }

    init() {
        this.slide_scroll = this.target.querySelector( '.slide_scroll' );
        this.counter = 0;
        this.item_list = this.target.querySelectorAll( '.slide .item' );
        this.item_list[ 0 ].classList.add( 'active' );
        this.last_active_item = this.item_list[ 0 ];
        let counter_init = 0;
        this.item_list.forEach( o => {
            o.setAttribute( 'data-id', counter_init );
            counter_init++;
        });
        this.itemClick();
    }

    itemClick() {
        this.item_list.forEach( o => {
            o.addEventListener( 'pointerup', () => {
                let id = o.dataset.id;
                this.counter = Number( id );
                this.move();
            } );
        });
    }

    move() {
        if( this.last_active_item ) this.last_active_item.classList.remove( 'active' );
        this.item_list[ this.counter ].classList.add( 'active' );
        this.last_active_item = this.item_list[ this.counter ];
        const item_width = this.item_list[ this.counter ].getBoundingClientRect().width;
        
        let offset;
        if( this.counter == 0 && this.counter < this.item_list.length - 1 ) {
            offset = 0;
        } else if( this.counter == this.item_list.length - 1 ) {
            offset = window.innerWidth - item_width;
        } else {
            offset = window.innerWidth * 0.5 - item_width * 0.5;
        }

        const item_pos = this.item_list[ this.counter ].getBoundingClientRect().x;
        const slide_pos = this.slide_scroll.getBoundingClientRect().x;

        let move_x = item_pos * -1 + slide_pos + offset;

        gsap.to( this.slide_scroll, {
            duration: 0.5,
            ease: 'power3.inOut',
            x: move_x
        } );
    }
}