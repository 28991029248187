////////////////////////////////////////////////////////////////
//
//  ©PRAKT
//
////////////////////////////////////////////////////////////////

import gsap from 'gsap';

////////////////////////////////////////////////////////////////
//
//  HOME
//
////////////////////////////////////////////////////////////////

export default class HeaderSlide {

    constructor() {
        this.slides;
        this.counter;
        this.loop;
        this.loop_start;
        this.loop_time;
        this.next_slide;
        this.last_slide;
        this.init();
    }

    init() {
        this.counter = 1;
        this.slides = document.querySelectorAll( '.header_slide .item' );
        this.loop_time = 1500;
        this.loop = requestAnimationFrame( this.automatLoop.bind( this ) );
        this.last_slide = this.slides[ 0 ];
    }

    changeSlide() {
        this.next_slide = this.slides[ this.counter ];
        this.last_slide.style.position = 'absolute';
        this.last_slide.style.left = 0;
        this.last_slide.style.top = 0;
        this.next_slide.style.position = 'static';

        gsap.to( this.last_slide, {
            duration: 1,
            ease: 'power3.inOut',
            opacity: 0
        } );

        gsap.to( this.next_slide, {
            duration: 1,
            ease: 'power3.inOut',
            opacity: 1
        } );

        this.last_slide = this.next_slide;

        if( this.counter < this.slides.length - 1 ) this.counter++;
        else this.counter = 0;
    }

    ////////////////////////////////////////////////////////////////
    //
    //  AUTOMAT
    //
    ////////////////////////////////////////////////////////////////

    automatLoop( timestamp ) {
        if( this.loop_start === undefined ) {
            this.loop_start = timestamp;
        }
        const elapsed =  timestamp - this.loop_start;
        if( elapsed < this.loop_time ) {
            this.loop = requestAnimationFrame( this.automatLoop.bind( this ) );
        } else {
            // console.log( 'RAF LOOP' );
            this.changeSlide();
            this.loop_start = timestamp;
            this.loop = requestAnimationFrame( this.automatLoop.bind( this ) );
        }
    }
}