////////////////////////////////////////////////////////////////
//
//  ©PRAKT
//
////////////////////////////////////////////////////////////////

import { scroll } from '../index';
import Slide from './Slide';
import TitleScoller from './TitleScroller';

////////////////////////////////////////////////////////////////
//
//  HOME
//
////////////////////////////////////////////////////////////////

export default class Home {

    constructor() {
        this.art_button;
        this.about_button;
        this.slides;
        this.slide_arr;
        this.slide_counter;
        this.titles;
        this.title_arr;
        this.title_counter;
        this.init();
    }

    init() {
        this.art_button = document.querySelector( '.content_header .art_button' );
        this.about_button = document.querySelector( '.content_header .about_button' );
        this.titles = document.querySelectorAll( '.large_title' );
        this.slides = document.querySelectorAll( '.slide_container' );
        this.slide_counter = 0;
        this.slide_arr = [];
        this.slides.forEach( o => {
            this.slide_arr[ this.slide_counter ] = new Slide( o );
            this.slide_counter++;
        });
        this.title_counter = 0;
        this.title_arr = [];
        this.titles.forEach( o => {
            this.title_arr[ this.title_counter ] = new TitleScoller( o );
            this.title_arr[ this.title_counter ].init();
            this.title_counter++;
        });
        this.buttonAction();
    }

    buttonAction() {
        this.art_button.addEventListener( 'pointerup', () => {
            const target = this.slides[ 0 ];
            scroll.scrollto( target );
        } );

        this.about_button.addEventListener( 'pointerup', () => {
            const target = document.querySelector( '.content_about' );
            scroll.scrollto( target );
        } );
    }

    checkHash() {
        const hash = window.location.hash;
        if( hash ) {
            const hash_clean = hash.substring( 1, hash.length );
            const target = document.querySelector( '.'+hash_clean );
            setTimeout( () => {
                scroll.scrollto( target );
            }, 500 );
        }
    }
}