////////////////////////////////////////////////////////////////
//
//  ©PRAKT
//
////////////////////////////////////////////////////////////////

import gsap from 'gsap';
import { scroll } from '../index';

////////////////////////////////////////////////////////////////
//
//  REPROGRAPHY DRAWER
//
////////////////////////////////////////////////////////////////

export default class ReprographyDrawer {

    constructor() {
        this.drawer_buttons;
        this.init();
    }

    init() {
        this.drawer_buttons = document.querySelectorAll( '.reprography_container .drawer_button' );
        if( this.drawer_buttons.length > 0 ) this.buttonAction();
    }

    buttonAction() {
        this.drawer_buttons.forEach( o => {
            o.addEventListener( 'pointerup', () => {
                let drawer = o.parentNode.parentNode.querySelector( '.drawer' );
                this.showHideDrawer( drawer );
            } );
        });
    }

    showHideDrawer( target_ ) {
        const target = target_;
        const button = target.parentNode.querySelector( '.drawer_button' );
        if( !target.classList.contains( 'active' ) ) {
            // open
            target.classList.add( 'active' );
            button.classList.add( 'active' );
            gsap.to( target, {
                duration: 0.5,
                ease: 'power3.inOut',
                height: 'auto',
                onComplete: () => {
                    scroll.updateScroll();
                }
            } );
        } else {
            // close
            target.classList.remove( 'active' );
            button.classList.remove( 'active' );
            gsap.to( target, {
                duration: 0.5,
                ease: 'power3.inOut',
                height: 0,
                onComplete: () => {
                    scroll.updateScroll();
                }
            } );
        }
    }
}